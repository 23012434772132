.pe-loader-layout {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pe-loader-box {
    /* position: absolute;
      top: 400px;
      left: 900px; */
    background: #000;
    display: inline-block;
    padding: 20px 30px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    display: inline-flex;
  }
  .pe-loader-caption {
    padding-left: 25px;
    line-height: 25px;
  }