// General styles

@font-face {
  font-family: "LiberationSans";
  src: url("LiberationSans-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "LiberationSans";
  src: url("LiberationSans-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "ANTIQUABI";
  src: url("ANTQUABI.TTF");
}
@font-face {
  font-family: 'Cursiv';
  src:url('Cursiv.ttf.woff') format('woff'),
      url('Cursiv.ttf.svg#Cursiv') format('svg'),
      url('Cursiv.ttf.eot'),
      url('Cursiv.ttf.eot?#iefix') format('embedded-opentype'); 
      font-weight: normal;
      font-style: normal;
}
@font-face {
  font-family: 'MonotypeCorsiva';
  src:
      url('Monotype Corsiva.ttf') format("truetype");
      font-weight: normal;
      font-style: normal;
}
@font-face {
  font-family: 'BarlowCondensed-Regular';
  src:
      url('./BarlowCondensed/BarlowCondensed-Regular.ttf') format("truetype");
      font-weight: normal;
      font-style: normal;
}
@font-face {
  font-family: 'BarlowCondensed-Medium';
  src:
      url('./BarlowCondensed/BarlowCondensed-Medium.ttf') format("truetype");
      font-weight: normal;
      font-style: normal;
}

.table {
  thead th {
    padding-top: $table-head-spacer-y;
    padding-bottom: $table-head-spacer-y;
    font-size: $table-head-font-size;
    text-transform: $table-head-text-transform;
    font-weight: $font-weight-bolder;
    border-top: 0;
  }

  &.comparison-table {
    thead th {
      font-size: 1.25rem;
      color: $dark;
    }

    td {
      font-size: $font-size-base;
    }
  }

  th {
    font-weight: $font-weight-bold;
    padding: 1rem;
  }

  td {
    .progress {
      height: 3px;
      width: 120px;
      margin: 0;
    }
  }

  td,
  th {
    font-size: $table-body-font-size;
    white-space: nowrap;
  }

  // User Table

  &.user-table {
    thead th {
      border-top: 0;
    }
  }

  // Vetical align table content

  &.align-items-center {

    td,
    th {
      vertical-align: middle;
    }
  }

  // Styles for dark table

  .thead-dark {
    th {
      background-color: $dark;
      color: $white;

      a {
        color: $dark;
      }
    }
  }

  // Styles for light table

  .thead-light {
    th {
      background-color: $soft;
      color: $dark;

      a {
        color: $dark;
      }
    }
  }
}

// Add transition for hover state

.table-hover {
  tr {
    @include transition($transition-base);
  }
}

// Flush tables

.table-flush {

  td,
  th {
    border-left: 0;
    border-right: 0;
  }

  tbody {
    tr {
      &:first-child {

        td,
        th {
          border-top: 0;
        }
      }

      &:last-child {

        td,
        th {
          border-bottom: 0;
        }
      }
    }
  }
}

// Tables inside cards

.card {
  .table {
    margin-bottom: 0;

    td,
    th {
      padding-left: $card-spacer-x;
      padding-right: $card-spacer-x;
    }
  }
}

.table {
  [data-sort] {
    cursor: pointer;
  }

  .custom-toggle {
    display: block;
  }

  .thead-dark {
    [data-sort] {
      &::after {
        content: str-replace(url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='#{$dark}'/></svg>"),
            "#",
            "%23"
          );
        margin-left: 0.25rem;
      }
    }
  }

  .thead-light {
    [data-sort] {
      &::after {
        content: str-replace(url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='#{$dark}'/></svg>"),
            "#",
            "%23"
          );
        margin-left: 0.25rem;
      }
    }
  }
}

.dataTables_wrapper {
  .dataTables_info {
    padding-left: 1.5rem;
  }

  .dataTables_paginate {
    padding-right: 1.5rem;
  }

  .dataTables_length {
    label {
      padding-left: 1.5rem;
    }
  }

  .dataTables_filter {
    padding-right: 1.5rem;
  }
}

.dataTable-top,
.dataTable-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-direction: column;

    .dataTable-info {
      margin-bottom: 1rem;
    }
  }
}

.dataTable-top {
  padding: 0 1.5rem 1rem 1.5rem;
}

.dataTable-bottom {
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.dataTable-pagination {
  display: flex;
  @include list-unstyled();

  a {
    padding: $pagination-padding-y $pagination-padding-x;
    @include font-size(null);
  }

  li {
    @if $pagination-margin-start==(-$pagination-border-width) {
      &:first-child {
        a {
          @include border-start-radius($pagination-border-radius);
        }
      }

      &:last-child {
        a {
          @include border-end-radius($pagination-border-radius);
        }
      }
    }

    @else {

      //Add border-radius to all pageLinks in case they have left margin
      a {
        @include border-radius($pagination-border-radius);
      }
    }
  }

  >li {
    &:not(:first-child) a {
      margin-left: $pagination-margin-start;
    }

    &.active a {
      z-index: 3;
      color: $pagination-active-color;
      @include gradient-bg($pagination-active-bg);
      border-color: $pagination-active-border-color;
    }

    &.disabled a {
      color: $pagination-disabled-color;
      pointer-events: none;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border-color;
    }

    a {
      position: relative;
      display: block;
      color: $pagination-color;
      text-decoration: if($link-decoration==none, null, none);
      background-color: $pagination-bg;
      border: $pagination-border-width solid $pagination-border-color;

      &:hover {
        z-index: 2;
        color: $pagination-hover-color;
        text-decoration: if($link-hover-decoration==underline, none, null);
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border-color;
      }

      &:focus {
        z-index: 3;
        outline: $pagination-focus-outline;
        box-shadow: $pagination-focus-box-shadow;
      }
    }
  }
}

.dataTable-selector {
  display: inline-block;
  width: auto;
  height: $form-select-height;
  padding: $form-select-padding-y (
    $form-select-padding-x + $form-select-indicator-padding) $form-select-padding-y $form-select-padding-x;
  font-family: $form-select-font-family;
  @include font-size($form-select-font-size
);
font-weight: $form-select-font-weight;
line-height: $form-select-line-height;
color: $form-select-color;
vertical-align: middle;
background-color: $form-select-bg;
background-image: escape-svg($form-select-indicator);
background-repeat: no-repeat;
background-position: $form-select-bg-position;
background-size: $form-select-bg-size;
border: $form-select-border-width solid $form-select-border-color;
@include border-radius($form-select-border-radius, 0);
@include box-shadow($form-select-box-shadow);
appearance: none;

&:focus {
  border-color: $form-select-focus-border-color;
  outline: 0;

  @if $enable-shadows {
    @include box-shadow($form-select-box-shadow,
      $form-select-focus-box-shadow );
  }

  @else {
    // Avoid using mixin so we can pass custom focus shadow properly
    box-shadow: $form-select-focus-box-shadow;
  }

  &::-ms-value {
    // For visual consistency with other platforms/browsers,
    // suppress the default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in Edge.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}

&[multiple],
&[size]:not([size="1"]) {
  height: auto;
  padding-right: $form-select-padding-x;
  background-image: none;
}

&:disabled {
  color: $form-select-disabled-color;
  background-color: $form-select-disabled-bg;
  border-color: $form-select-disabled-border-color;
}

// Remove outline from select box in FF
&:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 $form-select-color;
}
}

.dataTable-input {
  display: block;
  width: 100%;
  min-height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  appearance: none; // Fix appearance for date inputs in Safari

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;

    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    }

    @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

.react-bootstrap-table {
  table {
    table-layout: auto !important;
  }
}

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0;
}

.search-label {
  width: 100%;
}

.uploads {
  margin-top: 2em !important;
}

//sathya added
.classcontainer {
  max-width: 1080px;
  background-color: #175009d1;
  height: auto !important;
  margin: 0 auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: 1em !important;
}

.innerBox {
  background-color: white !important;
  margin: 1em !important;
  border: 5px solid #a6b36d !important;
}

.companyname {
  height: auto !important;
}

.circle {
  margin-top: 1.3em;
  position: absolute;
  margin-left: 15.3em;
}

.circle1 {
  height: 15px;
  width: 15px;
  background-color: #1305ee;
  border-radius: 50%;
  display: inline-block;
}

.circle2 {
  margin-left: 3px;
  margin-right: 3px;
  height: 15px;
  width: 15px;
  background-color: #c51111;
  border-radius: 50%;
  display: inline-block;
}

.circle3 {
  height: 15px;
  width: 15px;
  background-color: #f1e014;
  border-radius: 50%;
  display: inline-block;
}

.nameColor1 {
  padding-left: 0.5em !important;
  margin: 2em auto !important;
  font-style: italic;
  font-size: 6em !important;
  color: #ff9800 !important;
  font-weight: 500;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.nameColor2 {
  font-style: italic;
  font-size: 6em !important;
  font-weight: 500;
  color: black !important;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.QACode {
  background-color: white !important;
  padding-top: 3em;
  display: flex;
}

.innerContent {
  background-color: #175009d1 !important;
  padding: 2em !important;
}

img {
  // margin: auto 1em !important;
  max-width: initial;
}

// .Code {
//   padding: 0 2em !important;
// }
.border {
  border: 5px solid #175009d1 !important;
  padding: 1.5em !important;
}

.HalfBorder {
  border: 5px solid #175009d1 !important;
  padding: 0.5em !important;
  /* margin-top: 2em !important; */
}

/* img,svg {
    width: 100% !important;
    min-width: 340px;
    border: 2px solid #175009d1 !important;
  } */

.Content1 {
  color: white;
  font-size: 1.5em !important;
  font-weight: bold;
}


.Content2 {
  color: #deca7f !important;
  font-size: 3.5em !important;
  font-weight: bold;
  text-align: center;
}

// hr:not([size]) {
//   height: 6px !important;
//   width: 70% !important;
//   background-color: #c4bd82 !important;
//   margin-top: 1px !important;
//   opacity: 1 !important;
//   margin-left: 0em !important;
// }

.Content3 {
  color: white !important;
  font-size: 1.5em !important;
  font-weight: 500;
  line-height: 1.3em !important;
  padding-top: 0.5em !important;
}

.terms {
  font-size: 1.1em;
  font-weight: 700;
  text-align: right;
  padding-right: 2em;
  padding-top: 10px;
}

.signaturePart {
  // display: flex;
  margin: 1.5em 2em !important;
}

.SignBox {
  display: flex;
  border: 2px solid #deca7f !important;
  width: auto !important;
}

.signYear {
  background-color: #c0b58b !important;
  padding: 1em;
}

.wishes {
  color: #175009d1 !important;
  font-size: 0.9em;
  font-weight: bold;
}

.year {
  color: #175009d1 !important;
  font-size: 4em;
  font-weight: bold;
  // margin: -0.2em 0em -0.3em -0.1em !important;
}

.signMsg {
  color: #175009d1 !important;
  font-size: 2.5em;
  font-weight: bold;
  margin-left: 0.3em !important;
}

.success {
  margin: 0.1em 0em -0.3em -0.1em !important;
}

.signName {
  color: black;
  font-size: 0.8em;
  padding: 5em 0 0 4em;
  font-weight: bold;
  white-space: nowrap;
}

.signRole {
  padding: 0 0 0 3em;
  font-size: 0.8em;
  color: green;
  text-align: center;
}

.signName2 {
  font-size: 0.8em;
  padding: 5em 0 0 2em;
  font-weight: bold;
  white-space: nowrap;
}

.signRole2 {
  padding: 0 0 0 3em;
  font-size: 0.8em !important;
  color: green;
}

.sign {
  display: flex;
}

.signaturepart2 {
  // display: flex;
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .signaturePart {
    margin: 1.5em 1em;
  }

  .signYear {
    padding: 0.5em;
  }

  .signMsg {
    margin-left: 0.3em !important;
    font-size: 2.5em !important;
  }

  .signName,
  .signName2 {
    padding: 4em 2em 1em 2em;
  }

  .signRole,
  .signRole2 {
    padding: 0 0 0 1em;
  }
}

@media screen and (max-width: 956px) {
  .circle {
    margin-left: 12.3em !important;
    margin-top: 1em;
  }

  .Content1,
  .Content3 {
    font-size: 1em !important;
  }

  .Content2,
  .year {
    font-size: 3em !important;
  }

  .signMsg {
    font-size: 2em !important;
  }

  .terms,
  .wishes {
    font-size: 0.8em !important;
  }

  .nameColor1,
  .nameColor2 {
    font-size: 5em !important;
  }

  .signName,
  .signName2,
  .signRole,
  .signRole2 {
    font-size: 0.7em !important;
    white-space: nowrap;
  }

  .signName,
  .signName2 {
    padding-top: 6em !important;
  }
}

@media screen and (max-width: 810px) {
  .signMsg {
    font-size: 1.5em !important;
  }

  .wishes {
    font-size: 0.6em !important;
  }

  .year {
    font-size: 2.5em !important;
  }
}

@media screen and (max-width: 845px) {
  .signMsg {
    font-size: 1.5em !important;
  }
}

@media screen and (max-width: 725px) {

  .QACode,
  .signaturePart {
    display: block !important;
  }

  .signMsg {
    font-size: 2em !important;
  }

  .signaturepart2 {
    display: flex;
  }

  .Sign1,
  .Sign2 {
    width: 50% !important;
  }
}

@media screen and (max-width: 540px) {

  .signName,
  .signRole {
    padding-left: 0em !important;
  }
}

@media screen and (max-width: 470px) {
  .signMsg {
    font-size: 1.5em !important;
  }
}

@media screen and (max-width: 450px) {
  .year {
    font-size: 1.8em !important;
  }

  .signMsg {
    font-size: 1.2em !important;
  }

  .signaturepart2 {
    display: block;
    padding-left: 2.5em;
  }
}

.button {
  background-color: blue;
  color: white;
  padding: 0.5em;
  border-radius: 1em;
}

.center {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.Post {
  /* max-width: 1080px; */
  /* width: 100%; */
  height: auto;
  display: table;
  margin: 0 auto;
  /* padding: 20px 50px; */
  orientation: "landscape";
}

.App {
  text-align: center;
}

.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}

label {
  width: 350px;
  box-sizing: border-box;
  // margin: 2em 17em;
}

.bulkUploads {
  text-align: center;
}

button.upbutton {
  background-color: #262b40;
  height: 40px;
  width: 130px;
  border-radius: 5px;
  color: white;
  font-weight: 700;
}

p.Innertext {
  font-size: larger;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Upload {
  margin-top: 10px !important;
  text-align: center;
  /* background-color: #262B40; */
}

.DropFiles {
  width: 100%;
  border: 2px solid;
  /* height: 100%; */
  text-align: center;
  justify-content: center;
}

//Certificate



.leftsideImage {
  border-left: 1px solid #7a7b3f;
  border-top: 1px solid #7a7b3f;
  border-bottom: 1px solid #7a7b3f;
  width: 100% !important;
  height: 100% !important;
}

.rightContainer {
  display: flex;
    align-items: center;
    justify-content: center;
}
.logo {
  // height: 20% !important;
  background-color: white;
  // padding: 0.6em !important;
}

.logoImg {
  height: auto !important;
  margin: auto 25px;
  // padding-top: 1em;
}

.namePart {
  // width: 105.5% !important;
  // height: 22% !important;
  padding-top: 0.1em !important;
  padding-left: 2em !important;
}

.User {
  color: #000 !important;
  font-family: "BarlowCondensed-Regular" !important;
  font-size: 1.5em;
  font-style: normal;
  // letter-spacing: 0.1em;
  // font-weight: 600;
}

.comm_div {
  margin-left: 25px;
  display: flex;
  margin-top: 14px;
  align-items: center;
  // font-family: "LiberationSans" !important;
}

.text {
  margin-left: 1em;
  text-align: left;
  margin-top: -2px;
  font-size: 18px !important;
  color: #037039;
  font-family: "BarlowCondensed-Regular" !important;
}

.sign {
  font-size: 13px;
  font-style: normal;
}

.userName {
  font-family: "BarlowCondensed-Medium" !important;
  font-style: normal;
  // font-weight: 600 !important;
  font-size: 3.8em;
  line-height: 2em;
  color: #006738;
  letter-spacing: 0.05em;
  white-space: nowrap;
}

mark {
  padding: 0px !important;
}

.mainContainer {
  max-width: 1100px;
  height: 1122px;
  padding: 16px;
  margin: 0 auto;
  // background-color: #fff;
  // position: relative;
  
}
.imageContainer {
  max-width: 550px;
  height: 1122px;
  padding: 16px;
  margin: 0 auto;
  // background-color: #fff;
  position: absolute;
  width: 100%;
  display: flex
;
  justify-content: center;
  /* align-items: center; */
  overflow: hidden;
  right: 0;
  top : -17px
}
.containerInner {
  border: 3px solid #24b354;
  height: 740px;
  margin-top: 25px;
  display: flex;
}

.rightContainer,
.leftContainer {
  width: 50%;
  position: relative;
}

// .rightContainer {
//   background-size: cover;
//   background-repeat: no-repeat;
// }

.rightContainer.curve {
  overflow: hidden;
  position: relative;
  width: 50%;
  // background-image: url("../../../assets/img/INTGG2187.jpg");
  // background-size: contain;
}

.rightContainer img.img-rounded {
  border-radius: 50%;
}

// .position-relative {
//   position: absolute !important;
//   transform: rotate(-90deg);
//   bottom: -68px;
//   height: 600px;
//   left: 83px;
//   overflow: hidden;
//   z-index: 10;
// }

.position-relative.TopImage {
  position: absolute;
  transform: rotate(-90deg);
  height: 600px;
  left: 97px;
  // background-image: linear-gradient(to right, #137046, #00c12a);
}

img.leaf2 {
  position: absolute;
  bottom: 0;
  left: -18px;
  z-index: 999999;
  // height: 500px;
  width: 170px;
}

img.leaf {
  position: absolute;
  bottom: 0;
  left: -18px;
  z-index: 999999;
  // height: 500px;
  width: 170px;
}

@font-face {
  font-family: "DejaVu Sans";
  font-size: 20px;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-size: 20px;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  font-size: 20px;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  font-size: 20px;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

.dots {
  // color: white !important;
  position: relative;
  // top: -22px;
  // font-weight: bold;
  padding-right: 1em;
  letter-spacing: 0.12em;
}

.centerContent {
  background-color: white;
  // height: 30% !important;
  text-align: center;
  width: 525px;
  margin-top: 20px;
}

div.first {
  margin-top: -36px;
  margin-left: -30px;
  color: #496251;
  padding: 10px 0 1.5em 1.5em;
  font-size: 19px !important;
}

.content1 {
  font-weight: 500;
  font-size: 14px !important;
  font-style: normal;
  padding: 25px 1px 30px 0px!important;
  text-align: justify;
  font-family: "BarlowCondensed-Medium" !important;
  color: #4c6553;
  padding-left: 63px !important;
}

.wishes {
  color: #fff !important;
  font-size: 53px !important;
  text-align: left;
  padding-left: 100px !important;
  font-weight: normal;
  background-color: #127039;
  height: 170px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  margin-right: 20px;
  position: relative;
  left: -75px;
  display: flex;
  align-items: center;
  font-family: "BarlowCondensed-Regular";
  letter-spacing: 0.02em;
  width : 110%;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -webkit-text-stroke: 0.3px black;
}

.line {
  height: 1px !important;
  width: 65% !important;
  background-color: #85d39d !important;
  margin-top: 11px !important;
  margin-bottom: 11px;
  opacity: 1 !important;
  margin-left: 3.9em !important;
}
.line2 {
  height: 1px !important;
  width: 64% !important;
  background-color: #85d39d  !important;
  margin-top: 11px !important;
  margin-bottom: 11px;
  opacity: 1 !important;
  margin-left: 5em !important;
}
.content2 {
  padding-top: 1em;
  font-size: 20px;
  word-spacing: 1ch;
  color: #5d6660 !important;
  font-weight: 400;
  font-family: "LiberationSans" !important;
}

.content2 .prosp {
  font-size: 2em;
  letter-spacing: 0.1em;
  color: #c52228;
  line-height: 1.5em;
  font-family: "LiberationSans" !important;
}

.content2 .year {
  font-size: 1.5em;
  font-weight: 600;
  color: #363c38 !important;
  text-align: center !important;
  margin: -0.2em 0em -0.3em -2.1em !important;
}

div.label {
  color: #b1aaaa;
  font-size: 1em !important;
  margin: 1em 3em 1em 3em;
}

div.label.first {
  margin: 1em 3em 1em 3em;
}

div.value {
  margin-left: 2em;
  font-size: 1.5em;
  font-family: auto;
  font-weight: 400 !important;
}

.wishesimg {
  width: 45%;
}

.signaturePart {
  height: 15% !important;
  background-color: white;
}

.signName {
  padding-left: 1.5em;
  padding-top: 2em;
  font-size: 14px;
  font-style: italic;
}

.role {
  font-size: 13px;
  padding-left: 1em;
  font-style: italic;
}
.Role {
  font-size: 16px;
}

/* Second page CSS */

.leftsidePara {
  color: #fff;
  font-family: "LiberationSans";
  padding: 1em;
  line-height: 1.5em;
  position: absolute;
  bottom: 0px;
  width: 73%;
  left: 19%;
}

.namePart2 {
  width: 105.5% !important;
  color: white;
  height: 25% !important;
  background-color: #3b632f !important;
  padding-top: 0.1em !important;
  padding-left: 1em !important;
  font-style: italic;
  font-size: 21.5px !important;
  font-family: "Times New Roman", Times, serif;
}

.QRPage {
  padding: 25px 0px;
  position: absolute !important;
  bottom: 0 !important;
}

.tdcolon {
  padding: 0 1em;
}

.dots2 {
  position: relative;
  top: 7px;
  left: -7.4em;
}

img.Code {
  width: 100% !important;
}

.QR {
  // position: absolute;
  // bottom: 0 !important;
  // width: 115%;
  //   background-color: #0e9547;
}


img.Code {
  width: 80% !important;
  padding: 1em;
}

.QRContents {
  font-size: 25px !important;

  font-family: "LiberationSans" !important;
}

.QRcontent1 {
  font-family: "BarlowCondensed-Medium";
  font-size: 20px;
  margin-left: 20px;
  // color: #fff;
}

.Digital {
  line-height: 58px;
  font-size: 53px !important;
  color: #175009d1 !important;
  font-style: italic;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
}

.name2 {
  float: right;
  padding-right: 3em;
}

.name1 {
  padding-left: 3em;
}

.role11 {
  padding-left: 1em;
}

.role12 {
  float: right;
  padding-right: 3em;
}

//User Page
.container1 {
  height: auto !important;
  margin: 0 auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.image1 {
  height: 43em !important;
  width: 100% !important;
}

.nameDetails {
  position: relative;
  margin-top: -35em !important;
}

.container2 {
  height: auto !important;
  margin: 0px 250px !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.img1dear {
  font-size: 35px !important;
  color: white !important;
  font-family: "Nunito Sans";
}

.img1name {
  letter-spacing: 3px !important;
  font-weight: 600;
  margin-top: -21px;
  font-size: 100px;
  color: white !important;
  font-family: "Palatino Linotype";
  font-style: italic;
}

.img1text {
  font-size: 20px !important;
  color: white !important;
  line-height: 30px;
  font-family: inherit;
  margin-bottom: 80px;
  margin-right: 41px;
  margin-top: -13px;
}

.container21 {
  margin-top: 1em;
  background-color: #3b632f !important;
  position: relative;
  border-radius: 1.5em;
  padding-bottom: 1em;
}

.secondImage {
  padding: 22px 40px 0px 40px !important;
  display: flex;
  border-radius: 2em !important;
  height: auto !important;
}

.image2 {
  width: 300px !important;
  height: 90%;
  border: 2px solid white;
  border-radius: 1.7em;
  margin: 1em !important;
}

.image3 {
  border-radius: 1.7em;
  box-shadow: 2px 3px #111111;
}

.img2text {
  width: 60% !important;
  color: #ffffff;
  display: block;
}

table,
.c21text {
  margin: 1em !important;
}

td.colon {
  padding: 1em 2em;
}

// .secondImage {
//   padding-left: 0.9em;
// }
td.trLeft,
td.trRight {
  font-size: 15px;
}

.c21text {
  margin: 20px 50px 0px 0px !important;
  font-size: 15px;
}

.normalText {
  text-align: center !important;
  color: #2a2a2c !important;
  padding-top: 1em;
  font-size: 30px;
  font-weight: 500 !important;
}

.thirdimage {
  width: 100% !important;
  background-color: #dee4ed;
}

.icons {
  text-align: center;
  color: green;
  padding-bottom: 1em;
}

.footer1 {
  padding: 12px 12px 30px 12px !important;
  padding: 12px;
  color: white !important;
  font-family: var(--bs-font-sans-serif);
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
  background-color: #161914;
}

.footerText {
  text-align: center;
}

.footerText2 {
  text-align: center;
}

.thirdImage {
  background-color: #dee4ed;
  margin-top: 2em;
}

// .listBorder {
//   max-width: 750px;

// }
.li:before {
  font-family: "FontAwesome";
  content: "\f001";
  margin: 0 5px 0 -15px;
}

li {
  font-size: 1em;
  padding: 0.5em 0;
}

.greenColor {
  color: #53ae3b;
}

svg.bi.bi-info,
svg.bi.bi-twitter,
svg.bi.bi-facebook,
svg.bi.bi-google {
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.QuoteColor {
  color: #000 !important;
  margin-left: 5px;
}

.imageIcons {
  fill: #3a9409;
  width: 4% !important;
  margin-left: 0.5em !important;
}

.GreenQuotes {
  padding-top: 40px !important;
  display: flex;
  background-color: #d8e7f1;
  width: 100% !important;
  font-size: 1.5em !important;
}

.button1 {
  color: green;
  position: relative;
  left: 41em;
  font-size: 14px !important;
  border-radius: 12px;
}

.colon {}

.lowerContent {
  margin: 0px 235px 0px 250px !important;
}

@media screen and (min-width: 360px) and (max-width: 1000px) {
  .button {
    left: 25em;
  }
}

@media screen and (min-width: 360px) and (max-width: 700px) {
  .nameDetails {
    position: relative;
    margin-top: -36em !important;
  }

  .imagePart {
    margin: -140px 0px 0px 0px !important;
    height: 346px;
  }

  .secondImage {
    margin-left: 40px;
    margin-right: 40px;
    padding: 0px !important;
    margin-top: 165px !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 555px) {
  .GreenQuotes {
    justify-content: center !important;
    display: flex !important;
  }

  .c21text {
    padding-top: 40px !important;
    margin: 0px !important;
    padding-right: 0px !important;
    text-align: center !important;
    font-size: 15px !important;
  }

  .bottomzoom {
    display: none !important;
    width: 100% !important;
  }

  .secondImage {
    margin-left: 40px;
    margin-right: 40px;
    padding: 0em !important;
    margin-top: 165px !important;
    display: grid;
  }

  .image2 {
    width: 90% !important;
    position: relative;
    top: -3em;
  }

  .container2 {
    margin: 0px !important;
    max-width: 620px !important;
  }

  .button {
    visibility: hidden;
    left: 15em;
  }

  .lowerContent {
    margin: 0 1em !important;
  }

  .img1dear {
    font-size: 2em !important;
    padding-left: 1.5em !important;
    position: relative;
    top: -0.4em;
    color: white !important;
    font-family: "Nunito Sans";
  }

  .Ullist {
    margin-left: 15px !important;
  }

  span.Item {
    margin-left: 20px !important;
  }

  span.Flex {
    max-width: 5% !important;
  }

  li.list {
    max-width: 95% !important;
  }

  .img1name {
    //font-family: "Cormorant Garamond" !important;
    font-family: "EB Garamond" !important;
    padding: 0 0em 0em 1.1em !important;
    font-size: 3em !important;
    position: relative;
  }

  .ForMobile {
    font-size: 20px;
    display: block !important;
    text-align: center;
    color: #3a9409;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .imageIcons {
    fill: #3a9409;
    width: 10% !important;
  }

  span.Item {
    margin-left: 20px !important;
  }

  .LineA {
    display: none !important;
  }

  .LineB {
    display: none !important;
  }

  .Changes {
    margin-left: 0px !important;
  }

  .iiz .iiz__img {
    width: 300px !important;
    height: 390px !important;
  }

  .img1text {
    line-height: 20px !important;
    margin-top: 2px;
    padding: 0px 62px 0px 55px;
    position: relative;
    margin-right: 0px;
  }

  .ForText {
    padding: 0px 10px;
    max-width: 100% !important;
  }

  .container21 {
    border-radius: unset !important;
  }

  .c21text {
    //  padding-left: 1em !important;
  }

  .imagePart {
    margin: -140px 0px 0px 0px;
    order: 1;
  }

  .img2text {
    padding: 20px 10px 0px 10px !important;
    width: 100% !important;
    order: 2;
    position: relative;
  }
}

@media screen and (min-width: 450px) and (max-width: 700px) {
  .img1text {
    line-height: 15px !important;
    margin-top: 0.5em !important;
  }

  .iiz .iiz__img {
    height: 340px !important;
  }

  .Changes {
    margin-left: 0px !important;
  }

  .LineA {
    display: none !important;
  }

  .LineB {
    display: none !important;
  }
}

@media screen and (max-width: 360px) {
  .img1dear {
    font-size: 1em !important;
    padding: 1em 0em 0.8em 3.4em !important;
  }

  .Ullist {
    margin-left: 15px !important;
  }

  span.Item {
    margin-left: 20px !important;
  }

  span.Flex {
    max-width: 5% !important;
  }

  li.list {
    margin-left: 15px !important;
  }

  .container2 {
    margin: 0px !important;
    max-width: 620px !important;
  }

  .nameDetails {
    position: relative;
    margin-top: -36em !important;
  }

  .bottomzoom {
    display: none !important;
    width: 100% !important;
  }

  .imagePart {
    height: 346px !important;
  }

  .img1name {
    font-family: "Cormorant Garamond" !important;
    font-size: 2em !important;
    padding-left: 1.6em !important;
  }

  .img1text {
    line-height: 15px !important;
    padding: 0px 60px 0px 55px !important;
    font-size: 0.7em !important;
  }

  .iiz .iiz__img {
    width: 256px !important;
    height: 335px !important;
  }

  .Changes {
    margin-left: 0px !important;
  }

  .LineA {
    display: none !important;
  }

  .LineB {
    display: none !important;
  }

  .image2 {
    width: 90% !important;
  }

  .button {
    left: 8em;
  }

  // .c21text {
  //     padding-left: 1em;
  //     padding-right: 1em;
  // }
  .imagePart {
    order: 1;
  }

  .img2text {
    width: 100% !important;
    order: 2;
  }

  .imageIcons {
    fill: #3a9409;
    width: 10% !important;
  }
}

@media screen and (min-width: 700px) and (max-width: 992px) {
  .nameDetails {
    margin-top: -22em !important;
  }

  .container2 {
    margin: 0px !important;
    max-width: 620px !important;
  }

  .button1 {
    left: 28em !important;
  }

  .lowerContent {
    max-width: 630px;
  }

  .imageIcons {
    fill: #3a9409;
    width: 4% !important;
  }

  span.Item {
    margin-left: 20px !important;
  }
}

@media screen and (min-width: 554px) and (max-width: 770px) {
  .nameDetails {
    margin-top: -19em !important;
  }

  .container2 {
    margin: 0px !important;
    max-width: 480px !important;
  }

  .img1dear {
    font-size: 18px !important;
  }

  .Ullist {
    margin-left: 15px !important;
  }

  span.Item {
    margin-left: 20px !important;
  }

  span.Flex {
    max-width: 5% !important;
  }

  li.list {
    margin-left: 15px !important;
  }

  .img1name {
    font-family: "Cormorant Garamond" !important;
    font-size: 65px !important;
  }

  .image2 {
    width: 180px !important;
  }

  .button1 {
    left: 24em !important;
  }

  .lowerContent {
    max-width: 480px;
  }

  .imageIcons {
    fill: #3a9409;
    width: 4% !important;
  }
}

span.name {
  font-weight: 800;
}

//Certificate designs
.managingdirector {
  position: relative;
  left: -16px;
  color: #173822;
  text-align: center;
  font-size: 13px !important;
  font-family: "BarlowCondensed-Medium" !important;
  font-style: normal;
}

.managingdirector1 {
  position: relative;
  color: #173822;
  text-align: center;
  font-size: 13px !important;
  font-family: "BarlowCondensed-Medium" !important;
  font-style: normal;
  left: 50px;
}

.mdrole {
  width: 50% !important;
  margin-left: 23px;
}

.Affiliated {
  justify-content: space-between;
  // margin: 121px -140px 0px -23px;
  font-family: "BarlowCondensed-Regular" !important;
}

.margin {
  margin-left: 35px;
  display: flex;
  align-items: center;
  // width: 78%;
  margin-bottom: 15px;
  font-family: "BarlowCondensed-Medium" !important;
}

.Dottedorder::after {
  content: "...................................................................";
  letter-spacing: 1px;
  /* Use letter-spacing to increase space between dots*/
  display: flex;
  margin-top: -22px !important;
}

// .Displaysettings {
//   position: relative;
//     width: 50%;
// }

.imagePart {
  margin: 10px 0px 0px 0px;
  border: 6px solid white;
  width: 100%;
  height: 400px;
  border-radius: 20px;
}

// .ForText {

// }

//For Action Button
button.actionbutton {
  border: none;
  background: none;
}

//For transaction
.Filter.dropdown {
  height: 39px;
  background-color: #2e3650;
  color: white;
  border-radius: 8px;
  white-space: nowrap;
  height: 40px !important;
  border-radius: 8px !important;
  margin-left: 20px !important;
  background-color: #2e3650;
}

.TextWhite {
  color: white;
  font-weight: 700;
}

select#DropDown {
  margin-left: 20px;
  white-space: nowrap;
  height: 40px !important;
  border-radius: 8px !important;
  background-color: #2e3650;
  color: white;
  font-weight: 700;
  max-width: 25%;
}

.Treesample {
  max-width: 100% !important;
  width: 100% !important;
  text-align: left !important;
  color: #4a5073 !important;
  margin-left: 0px !important;
  white-space: nowrap;
  height: 40px !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
  height: 39px;
  background: transparent !important;
}

.Treesample::after {
  background-color: blue;
}

label.gameyear {
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  width: 30%;
  padding: 10px;
}

.Options {
  background-color: white;
  margin: 5px !important;
  color: #000;
}

//images
img.output {
  width: 100% !important;
  height: 100% !important;
}

.dropdown-menu[style] {
  right: 0 !important;
}

.excelUploads {
  min-height: 445px;
}

table.Tabledesign {
  /* margin-top: 5px; */
  /* margin-top: 9px; */
  margin: 6px 0px 0px 0px !important;
}

.iiz__img--abs {
  border-radius: 10px !important;
}

.iiz__img {
  width: 365px !important;
  height: 390px !important;
  border-radius: 15px !important;
}

.iiz {
  max-width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: zoom-in;
}

.ForText {
  max-width: 40%;
}

.bottomzoom {
  width: 100%;
  background-color: #3b632f;
  justify-content: space-evenly;
  display: flex;
}

button.zoom {
  margin-top: 25px;
  border-radius: 17px;
  text-align: center;
  margin-bottom: 1rem;
  width: 40%;
  font-size: 13px !important;
  white-space: nowrap;
  color: #3b632f;
  background-color: white;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: none;
  line-height: 2px;
}

p.zoom {
  line-height: 32px;
  margin-top: 25px;
  border-radius: 17px;
  text-align: center;
  margin-bottom: 1rem;
  width: 40%;
  font-size: 13px !important;
  white-space: nowrap;
  color: #3b632f;
  background-color: white;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.ForMobile {
  display: none;
}

.Line hr {
  height: 1px;
  border: 0;
  background: black;
}

.Ullist {
  margin-bottom: 100px;
  margin-left: 20px;
  padding: 0 !important;
  list-style-type: none !important;
}

span.Item {
  margin-left: 10px;
  font-size: 18px;
  width: 100% !important;
  color: #000 !important;
}

span.Flex {
  max-width: 5% !important;
}

li.list {
  max-width: 97% !important;
  display: flex !important;
}

.LineA {
  width: 55.5%;
  height: 25px;
  border-bottom: 1px solid #837c7c;
  position: absolute;
  margin-left: 599px;
}

.LineB {
  width: 19% !important;
  height: 26px;
  border-bottom: 1px solid #837c7c;
  position: absolute;
  margin: 0 !important;
}

.TextChanges {
  display: flex;
  width: 10%;
}

.table td,
.table th {
  white-space: inherit !important;
}

.Changes {
  font-size: 40px;
  margin-left: 245px;
  display: flex;
  line-height: 46px;
}

.iiz__hint {
  display: none !important;
}

//leaves Images
img.leaves {
  top: -28px;
  position: relative;
}

.icons a {
  margin-right: 15px;
}

.imgTable {
  margin: 0em !important;
}

body {
  background-color: white !important;
}

.BottomArea {
  background-image: linear-gradient(to right, #4eab48, #a7cc44);
  color: #fff;
  width: 100%;
  min-height: 350px;
  padding: 32px 20px 0px 45px;
  position: absolute;
  bottom: 0;
}

.rightContainer.Two {
  background-size: contain !important;
}

.curve {
    height: 77% !important;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    width: 90%;
    position: absolute;
    object-fit: cover;
    z-index: 999;
    right: 0;
    // height: 100%;
    // background-repeat: no-repeat;
    // background-size: cover;
    // width: 90%;
    // position: absolute;
    // object-fit: cover;
    // z-index: 999;
    // /* right: 0; */
    // border-top-right-radius: 65px;
    // border-bottom-right-radius: 65px;
    // border-top-left-radius: 65px;
    // height: 65%;
    // width: 85%;

}
.rightContainer .initial {
  display: flex;
  justify-content: center;
  align-items: center;
  // position: absolute;
    // top: 14%;
    // left: 10%;
}
.outer-circle {
  border: 1px solid green;
  display: flex;
  justify-content: center;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  align-items: center;
}
.first-con {
  font-family: "BarlowCondensed-Regular";
    font-size: 19px;
    color: #037039;
    padding-bottom: 0 !important;
    padding-left: 36px;
    margin-top: 40px;
}
.green-para {
  padding: 12px 4px 32px 2px;
    transform: rotate(180deg);
    position: absolute;
    top: 95px;
    margin-right: 25px;
    margin-left: 205px;
    margin-bottom: 0;
}
.rightContainer .page2 {
//  clip-path: ellipse(95% 74% at 95% 66%) !important;
border-top-right-radius: 65px;
border-bottom-right-radius: 65px;
border-top-left-radius: 65px;
height: 75%;
width: 85%;
}
.outerImg {
  position: absolute;
    left: -36px;
    top: -43px;
}
.outerImg2 {
  transform: rotate(180deg);
  position: absolute;
    right: 18px;
    bottom: -35px;
    left: 785px;
}
.outerImg3 {
  width: 390px;
  position: relative;
  height: 20px;
  top: -24px;
  left: 50%;
}
.curveOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: url(#curveClipPath);
}
.ellipseImageCurveContainer {
  width: 100%;
  height: 100%;
  display: block;
}
.lshape2 {
  position: absolute;
  bottom: -83px;
  left: -174px;
  transform: rotate(180deg);
}
.header2 {
  display: flex;
  align-items: center;
}
.TreeDes {
  padding-left: 32px !important;
  font-family: "BarlowCondensed-Regular";
  font-size: 18px;
}